
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import BotWlcmHomeCampaignListCard from '@/components/bot/wlcm-home/BotWlcmHomeCampaignListCard.vue';
import BotWlcmHomeCampaignCreateModal from '@/components/bot/wlcm-home/BotWlcmHomeCampaignCreateModal.vue';
@Component({
  name: 'project-wlcm-home-campaigns',
  components: { BotWlcmHomeCampaignListCard, BotWlcmHomeCampaignCreateModal },
})
export default class ProjectWlcmHomeCampaigns extends mixins(ProjectViewMixin) {
  showModal = false;

  refresh() {
    (this.$refs.campaignListCard as BotWlcmHomeCampaignListCard).loadCampaigns();
  }
}
