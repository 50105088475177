
import { WlcmHomeApi } from '@/api/WlcmHomeApi';
import BotAgentCreateModal from '@/components/bot/BotAgentCreateModal.vue';
import AppColorPicker from '@/components/ui/AppColorPicker.vue';
import { AuthMixin } from '@/mixins/AuthMixin';
import { SelectOption, WlcmHomeCampaignSettings } from '@/types';
import { Agent } from 'ignite360-core/agent';
import QRCode from 'qrcode';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';


@Component({
  name: 'bot-wlcm-home-campaign-form',
  components: { AppColorPicker, BotAgentCreateModal },
})
export default class BotWlcmHomeCampaignForm extends mixins(AuthMixin) {
  @Prop({ required: true, type: Object })
  readonly value!: WlcmHomeCampaignSettings;

  @Prop({ required: true, type: Boolean, default: false })
  readonly create!: boolean;

  qrCodeDataUrl = '';
  showCreateAgentModal = false;

  agents: Agent[] = [];

  get selectOptions(): SelectOption[] {
    return this.agents.map((agent) => ({
      label: agent.name,
      value: agent.id,
    }));
  }

  onInput(key: string, value: any) {
    const updatedValue: WlcmHomeCampaignSettings = JSON.parse(JSON.stringify(this.value));
    
    if (key === 'agent') {
      value = this.agents.find((agent: Agent) => agent.id === value)
    }

    if (key.includes('.')) {
      const keys = key.split('.');
      let current: any = updatedValue;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!(keys[i] in current)) {
          current[keys[i]] = {};
        }
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;
    } else {
      (updatedValue as any)[key] = value;
    }

    this.$emit('input', updatedValue);
  }

 get publicUrl() {
    return `/home/${this.value.slug}`;
  }


  async mounted() {
    try {
      this.qrCodeDataUrl = await QRCode.toDataURL(this.publicUrl);
      await this.fetchBotAgents();
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  }

  async refresh(agent: Agent) {
    await this.fetchBotAgents();
    this.onInput('agent', agent.id);
  }


  async fetchBotAgents() {
    try {
      
    const response = await WlcmHomeApi.getBotAgents(
          this.$route.params.id,
        );

        this.agents = response.data;

      } catch (error) {
        this.$notify.error({
          title: 'Could not load agents',
          description: 'Could not load agents',
        });
      }
  }

  generateSlugFromName(name: string) {
    return name.toLowerCase().replace(/\s+/g, '-');
  }

  @Watch('value.name')
  onNameChange(newName: string): void {
    this.onInput('slug', this.generateSlugFromName(newName));
  }
}
