
import BotAvatar from '@/components/bot/BotAvatar.vue';
import { Bot, WlcmHomeCampaign } from 'ignite360-core';
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { AuthMixin } from '@/mixins/AuthMixin';
import { WlcmHomeApi } from '@/api/WlcmHomeApi';

@Component({
  name: 'bot-wlcm-home-campaign-list-card',
  components: { BotAvatar },
})
export default class BotWlcmHomeCampaignListCard extends mixins(AuthMixin) {
  campaigns: WlcmHomeCampaign[] = [];

  async mounted() {
      await this.loadCampaigns();
  }

  async loadCampaigns() {
    try {
      this.campaigns = await WlcmHomeApi.loadBotWlcmHomeCampaigns(this.$route.params.id);
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        description: 'Failed to load campaigns',
      });
    }
  }

  // sort by last updated desc
  sortCampaigns = (a: WlcmHomeCampaign, b: WlcmHomeCampaign) => {
    const lastUpdatedA = new Date(a.lastUpdatedAt).getTime();
    const lastUpdatedB = new Date(b.lastUpdatedAt).getTime();

    if (lastUpdatedA > lastUpdatedB) {
      return -1;
    } else if (lastUpdatedA < lastUpdatedB) {
      return 1;
    }
    return 0;
  };
  filterCampaigns = (campaign: WlcmHomeCampaign, filterBy: string) => campaign.name.toLowerCase().includes(filterBy);
  getCampaignKey = (campaign: WlcmHomeCampaign) => campaign.id;
}
