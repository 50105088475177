
import { WlcmHomeApi } from '@/api/WlcmHomeApi';
import BotWlcmHomeCampaignForm from '@/components/bot/wlcm-home/BotWlcmHomeCampaignForm.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { WlcmHomeCampaignSettings } from '@/types';
import isEqual from 'fast-deep-equal/es6';
import { CreateWlcmHomeCampaign } from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'bot-wlcm-home-campaign-create-modal',
  components: { BotWlcmHomeCampaignForm },
})
export default class BotWlcmHomeCampaignCreateModal extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  readonly show!: boolean;

  isSubmitting = false;

  campaignSettings: WlcmHomeCampaignSettings = this.resetValue();

  get settingsHaveChanged(): boolean {
    return !isEqual(this.campaignSettings, this.resetValue());
  }

  async onSubmit() {
    // const data: CreateWlcmHomeCampaign = {
    //   ...this.campaignSettings,
    // };
    this.isSubmitting = true;
    try {
      await WlcmHomeApi.createBotWlcmHomeCampaign(this.$route.params.id, {
        ...this.campaignSettings,
        // title: 'test',
        // description: 'test',
      });
      // await this.$store.dispatch('bots/create', data);
      this.$notify.success('Successfully created project');
      this.$emit('update:show', false);
      this.$emit('refresh');
    } catch (e) {
      this.$notify.error({
        title: 'Creating a project failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  resetValue(): WlcmHomeCampaignSettings {
    return {
      name: '',
      title: '',
      description: '',
      slug: '',
      styles: {
        title: {
          color: '#000000',
        },
        description: {
          color: '#000000',
        },
        background: {
          color: '#FFFFFF',
        },
      },
      date: '',
    };
  }

  @Watch('show')
  reset() {
    this.campaignSettings = this.resetValue();
  }
}
